import { useAuth } from "@clerk/nextjs";
import { Button, Stack } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useUser } from "@/auth/useUser";
import ErrorMessage, {
  FullPageError,
} from "@/components/common/errors/errorMessage";
import { LoadingAnimation } from "@/components/signin/loadingAnimation";
import { getDefaultRoute } from "@/utils";

function LandingView() {
  const { isLoaded, isSignedIn, signOut } = useAuth();

  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");

  const router = useRouter();
  const { user, additionalUserDetails } = useUser();

  useEffect(() => {
    // Clerk is still loading
    if (isLoaded) {
      if (!isSignedIn) {
        router.push("/sign-in");
      }

      if (user && !user.id) {
        // When user id does not exist, it means clerk user id
        // is not associated with any user in our database
        setErrorTitle("No account exists for this user");
        setErrorDescription("Please contact Moxie support.");
        return;
      }

      if (user?.role && additionalUserDetails) {
        if (additionalUserDetails.userMedspa?.length === 0) {
          // If user does not have any usermedspas, it means
          // they do not have any role in any medspa
          setErrorTitle("This account is not connected to any medspa");
          setErrorDescription(
            "Please contact your provider support manager to get access to Moxie Suite."
          );
          return;
        }

        if (additionalUserDetails.userMedspa?.length > 0) {
          // Filter out userMedspas where the user has role of a Client
          const switchableMedspas = additionalUserDetails.userMedspa;

          if (switchableMedspas.length > 0) {
            // we don't know what medspa user wants to log into
            // pick the first index for now
            // TODO: show beter UI to allow user to select which one they want
            router.push(getDefaultRoute(switchableMedspas[0]));
          }
        }
      }
    }
  }, [isLoaded, isSignedIn, user, additionalUserDetails, router]);

  const handleBackToLogin = async () => {
    await signOut();
    router.push("/sign-in");
  };

  if (errorTitle)
    return (
      <FullPageError>
        <ErrorMessage
          title={errorTitle}
          description={errorDescription}
          showActionButtons={false}
          customActionButtons={
            <Button variant="contained" onClick={handleBackToLogin}>
              Back to Login
            </Button>
          }
        />
      </FullPageError>
    );

  return (
    <Stack
      display="flex"
      alignContent="center"
      justifyContent="center"
      width="100%"
      height="100%"
      flexWrap="wrap"
      sx={{ minHeight: "90vh" }}
    >
      <LoadingAnimation title="Redirecting you to Moxie Suite" />
    </Stack>
  );
}

export default LandingView;
